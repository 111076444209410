/*import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/


import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
//import { BrowserRouter } from "react-router-dom";
//import { AuthContextProvider } from "./context/AuthContext";
//import { StyleProvider } from '@ant-design/cssinjs';
//import { legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <React.StrictMode>
   
      {/*<BrowserRouter>
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>*/}
          <App />
        {/*</StyleProvider>
      </BrowserRouter>*/}
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
