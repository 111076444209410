//import logo from './logo.svg';
import './App.css';
import MobilePage from './mobile';
import PCPage from './pc';
//import {isMobile} from 'react-device-detect';

const App=()=>{
  const isMobile = () => {
    const p = navigator.platform;
    if (p.indexOf("Win") == 0) {
        return false;
    } else if (p.indexOf("Mac") == 0) {
        return false;
    } else {
        return true;
    }
  };

  if (isMobile()) {
    return <MobilePage/>;
  } else {
    return <PCPage/>;
  }
}
/*function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/
export default App;
