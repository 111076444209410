import React, { useEffect, useState } from 'react';
import "./index.css";
import { Button, Tag } from "antd";

const MobilePage = () => {
  const urls = [
    'http://vip.gptchatclub.com',
    'http://vip1.gptchatclub.com',
    'http://vip2.gptchatclub.com',
    'http://vip.gptchatclub.org',
    'http://vip1.gptchatclub.org',
    'http://vip2.gptchatclub.org',
  ];

  const [speeds, setSpeeds] = useState(new Map());

  useEffect(() => {
    updateSpeeds();
  }, []);

  const measureSpeed = async (url) => {
    return new Promise((resolve, reject) => {
      const startTime = new Date().getTime();

      fetch(url, { mode: 'no-cors' })
      .then(() => {
        const endTime = new Date().getTime();
        resolve(endTime - startTime);
      })
      .catch((error) => {
        const endTime = new Date().getTime();
        console.error(`Error fetching ${url}:`, error);
        reject(new Error(`Failed to fetch ${url}: ${error.message}`));
      });
    });
  };

  const updateSpeeds = async () => {
    //const speedPromises = urls.map(url => measureSpeed(url));
    //const speedResults = await Promise.all(speedPromises);

    const speedMap = new Map();
    let minTime = 0;
    let gotoUrl = urls[0];
    let count = 0;
    for (let i = 0; i < urls.length; i++) {
      if (count >= 2) {
        break
      }
      const url = urls[i];
      try {
        const time = await measureSpeed(url);
        //const speed = speedResults[i];
        speedMap.set(url, time);
        if (minTime === 0) {
          minTime = time;
          gotoUrl = url;
        }
        if (minTime !== 0 && time < minTime) {
          minTime = time;
          gotoUrl = url;
        }
        if (time < 1000) {
          count ++;
        }
        //console.log(`${url}: ${time.toFixed(2)} ms`);
      } catch (error) {
        console.error(error.message);
      }
     
    }
    setSpeeds(speedMap);
    console.log("speedMap:", speedMap);
    console.log("url, time:", gotoUrl, minTime);
    redirectToFastestUrl(gotoUrl);
  };


  const redirectToFastestUrl = (url) => {
    window.location.href = url;
    return
    /*window.location.href = "http://vip.gptchatclub.com";
    const availableUrls = urls.filter(url => _speedMap.has(url));
    if (availableUrls.includes("http://vip.gptchatclub.com")) {
      // const minSpeedUrl = availableUrls.reduce((prevUrl, currUrl) => {
      //   return _speedMap.get(currUrl) < _speedMap.get(prevUrl) ? currUrl : prevUrl;
      // });
      // window.location.href = minSpeedUrl || "";
        window.location.href = "http://vip.gptchatclub.com";
    } else if (availableUrls.includes("http://vip1.gptchatclub.com")) {
        window.location.href = "http://vip1.gptchatclub.com";
    } else {
        window.location.href = "http://vip2.gptchatclub.com";
    }*/
  };


  return (
    <div className="container">
      <div className="title">Talk-Bot</div>
      <div className="linkItemBox">
        {
          urls.map((item, index) => {
            return (
              <div className="linkItem">
                <div>线路{index + 1}</div>
                {item}
                <div className="tagBox">
                  速度：
                  <Tag color="green">{speeds.has(item) ? `${speeds.get(item).toFixed(2)}ms` : "检测中"}</Tag>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="commonText">稍等片刻，页面自动跳转</div>
      <Button type="primary" style={{ marginTop: 20 }} onClick={() => redirectToFastestUrl(speeds)}>立即跳转</Button>
    </div>
  );
}

export default MobilePage;
